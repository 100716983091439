.navbar {
  .navbar--link {
    transition: 0.2s;

    &:active {
      background: #171717;
    }
  }

  .active {
    stroke-opacity: 1;
    color: white;

    .nav-icon {
      .chakra-avatar__svg {
        border-radius: 50%;
        border: 1.5px solid white;
      }

      svg {
        fill: #FFFFFF;

        path {
          transition: 0.3s;
          stroke: #202020;
        }
      }
    }
  }
}
