@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
a:hover {
  text-decoration: none;
}
%unscroll{
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 0;
  }
}

.unscroll{
  @extend %unscroll
}

%scroll{
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 4px;
  }
}
.scroll{
  @extend %scroll
}


button{
  cursor: pointer;
  &:active{
    opacity: .7;
  }
}
body{
  background: #151515;
  height: 100vh;
  width: 100vw;
}

html,
body {
  font-family: Roboto, sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend %unscroll;
}
